import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

function DownloadPage() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [filePaths, setFilePaths] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionId) {
      const fetchDownloadLinks = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download-session?session_id=${sessionId}`);
          if (!response.ok) {
            throw new Error(`Error fetching download links: ${response.statusText}`);
          }
          const data = await response.json();
          console.log('Download links JSON:', data);
          setFilePaths(data.filePaths || []);
        } catch (err) {
          console.error('Error fetching download links:', err);
          setError('Failed to load download links.');
        }
      };

      const fetchOrderDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch-prodigi-order?session_id=${sessionId}`);
          if (!response.ok) {
            throw new Error(`Error fetching order details: ${response.statusText}`);
          }
          const data = await response.json();
          console.log('Prodigi order JSON:', data);
          if (data.orderNumber) {
            setOrderDetails({
              orderNumber: data.orderNumber,
              orderItems: data.orderItems || [],
              shippingOption: data.shippingOption || {},
            });
          }
        } catch (err) {
          console.error('Error fetching order details:', err);
          setError('Failed to load order details.');
        }
      };

      const fetchData = async () => {
        setLoading(true);
        await Promise.all([fetchDownloadLinks(), fetchOrderDetails()]);
        setLoading(false);
      };

      fetchData();
    } else {
      setError('Invalid session. Please try again.');
    }
  }, [sessionId]);

  const handleContinueShopping = () => {
    navigate('/');
  };

  if (loading) {
    return <div>Loading your details, please wait...</div>;
  }

  if (error) {
    return (
      <div className="error-page container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Oops!</h2>
        <p>{error}</p>
        <button
          onClick={handleContinueShopping}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Return to Home
        </button>
      </div>
    );
  }

  return (
    <div className="download-page container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Thank You for Your Purchase!</h2>
      {filePaths.length > 0 ? (
        <div>
          <p>Your download links (opens in a new tab):</p>
          {filePaths.map((path, index) => (
            <div key={index}>
              <a
                href={`${process.env.REACT_APP_API_BASE_URL}${path}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                Download Wallpaper {index + 1}
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p>No wallpapers available for download.</p>
      )}

      {orderDetails && (
        <div className="mt-4">
          <h3 className="text-lg font-bold mb-2">Order Summary</h3>
          {orderDetails.orderNumber && <p>Order Number: {orderDetails.orderNumber}</p>}
          <div className="p-2 border rounded">
            <h4 className="font-bold mb-2">Ordered Items:</h4>
            {orderDetails.orderItems.length > 0 ? (
              <ul>
                {orderDetails.orderItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    <p>
                      <strong>Item:</strong> {item.description || 'Custom Phone Case'}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity || 1}
                    </p>
                    {item.price !== undefined && (
                      <p>
                        <strong>Price:</strong> £{item.price.toFixed(2)}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No items found in the order.</p>
            )}
          </div>
          {orderDetails.shippingOption?.label && (
            <p>
              <strong>Shipping Option:</strong> {orderDetails.shippingOption.label}
            </p>
          )}
          {orderDetails.orderItems.some(item => item.includePhoneCase) && (
            <p>
              Check your email for a separate notification about the phone case
              delivery from our production partners Prodigi. Your unique phone case will be with you in a few days!
            </p>
          )}
        </div>
      )}

      <button
        onClick={handleContinueShopping}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
      >
        Continue Shopping
      </button>
    </div>
  );
}

export default DownloadPage;
