import React from 'react';

function UpdatesForm({ onNavigateHome }) {
    return (
        <div className="updates-form-container">
            <div className="navigation">
                <button
                    onClick={onNavigateHome}
                    className="home-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Home
                </button>
            </div>
            <div id="mc_embed_signup">
                <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                <style type="text/css">
                    {`
                        #mc_embed_signup { background: #fff; clear: left; font: 14px Helvetica, Arial, sans-serif; width: 600px; }
                        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                    `}
                </style>
                <form action="https://aiwallpapergenie.us22.list-manage.com/subscribe/post?u=195827dc2626a774a3aeddc21&amp;id=94c1ed4181&amp;f_id=0070cae1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                    <div id="mc_embed_signup_scroll">
                        <h2>Subscribe</h2>
                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                            <input type="text" name="FNAME" className="required" id="mce-FNAME" required />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                        </div>
                        <div id="mce-responses" className="clear foot">
                            <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                            <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                        </div>
                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                            <input type="text" name="b_195827dc2626a774a3aeddc21_94c1ed4181" tabIndex="-1" value="" />
                        </div>
                        <div className="clear foot">
                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                        </div>
                    </div>
                </form>
                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
                <script type="text/javascript">
                    {`
                        (function($) {
                            window.fnames = new Array(); window.ftypes = new Array();
                            fnames[1]='FNAME'; ftypes[1]='text';
                            fnames[0]='EMAIL'; ftypes[0]='email';
                        }(jQuery));
                        var $mcj = jQuery.noConflict(true);
                    `}
                </script>
            </div>
        </div>
    );
}

export default UpdatesForm;
