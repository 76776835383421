import React, { useMemo, useState, useEffect } from 'react';

const Basket = ({ items, setBasket, onNavigateHome, colorNameFromHex }) => {
  const [phoneCases, setPhoneCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shippingOption, setShippingOption] = useState(null);
  const [email, setEmail] = useState('');
  const [purchaseDisabled, setPurchaseDisabled] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({
    name: '',
    line1: '',
    line2: '',
    postalOrZipCode: '',
    townOrCity: '',
    stateOrCounty: '',
    countryCode: '',
  });
  const [formErrors, setFormErrors] = useState({});

  // Shipping options
  const shippingOptions = [
    { id: 'budget', label: 'Budget Shipping (£2.10)', baseCost: 2.10, additionalCost: 0.50 },
    { id: 'express', label: 'Express Shipping (£5.50)', baseCost: 5.50, additionalCost: 1.00 },
    { id: 'overnight', label: 'Overnight Shipping (£9.95)', baseCost: 9.95, additionalCost: 2.00 },
  ];

  useEffect(() => {
    const detectCountry = () => {
      const defaultCountry = 'GB';
      const detectedCountry = navigator.language.includes('US')
        ? 'US'
        : navigator.language.includes('CA')
        ? 'CA'
        : defaultCountry;
      setDeliveryAddress((prev) => ({ ...prev, countryCode: detectedCountry }));
    };
    detectCountry();
  }, []);

  useEffect(() => {
    const fetchPhoneCases = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/phone-cases`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setPhoneCases(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching phone cases:', error);
        setIsLoading(false);
      }
    };

    fetchPhoneCases();
  }, []);

  const getBrands = () => [...new Set(phoneCases.map((pc) => pc.brand))];

  const getModelsByBrand = (brand) => {
    if (!brand) return [];
    const models = [
      ...new Set(
        phoneCases
          .filter((pc) => pc.brand === brand)
          .map((pc) => pc.productDescription.replace(`${brand} `, '').trim())
      ),
    ];
    return models.sort();
  };

  const calculateTotalCost = useMemo(() => {
    let total = items.reduce((total, item) => {
      let itemTotal = 2.00; // Base price for wallpaper
      if (item.includePhoneCase) {
        if (!item.phoneCaseBrand || !item.phoneCaseModel) {
          // If brand/model not selected, skip adding phone case cost (will handle at form validation)
          return total + itemTotal * (item.quantity || 1);
        }
        const phoneCase = phoneCases.find(
          (pc) =>
            pc.brand === item.phoneCaseBrand &&
            pc.productDescription.includes(item.phoneCaseModel)
        );

        if (phoneCase && typeof phoneCase.price === 'number') {
          const casePrice = phoneCase.price;
          itemTotal += (casePrice * 1.2 + 5.50) * (item.quantity || 1);
        } else {
          console.error(
            `Phone case not found or price missing for brand: ${item.phoneCaseBrand}, model: ${item.phoneCaseModel}`
          );
          itemTotal *= (item.quantity || 1);
        }
      } else {
        itemTotal *= (item.quantity || 1);
      }

      return total + itemTotal;
    }, 0);

    if (shippingOption && items.some((item) => item.includePhoneCase)) {
      const { baseCost = 0, additionalCost = 0 } = shippingOption;
      const phoneCaseCount = items.filter((item) => item.includePhoneCase).length;

      if (phoneCaseCount > 0) {
        total += baseCost;
        total += additionalCost * (phoneCaseCount - 1);
      }
    }

    return total;
  }, [items, shippingOption, phoneCases]);

  const handleRemoveItem = (url) => {
    setBasket((prev) => prev.filter((item) => item.url !== url));
  };

  const handlePurchase = async () => {
    if (purchaseDisabled) return; // Prevent duplicate submissions
    console.log("handlePurchase called");
    const errors = {};
    const requiresAddress = items.some((item) => item.includePhoneCase);
    const requiresEmail = requiresAddress;

    if (requiresEmail && !email) errors.email = 'Email is required';
    if (requiresAddress && !deliveryAddress.line1) errors.line1 = 'Address Line 1 is required';
    if (requiresAddress && !deliveryAddress.line2) errors.line2 = 'Address Line 2 is required';
    if (requiresAddress && !deliveryAddress.townOrCity) errors.townOrCity = 'Town or City is required';
    if (requiresAddress && !deliveryAddress.postalOrZipCode) errors.postalOrZipCode = 'Postal or Zip Code is required';
    if (requiresAddress && !shippingOption) errors.shippingOption = 'Shipping option is required';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setPurchaseDisabled(true); // Disable the button to prevent multiple submissions
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: items.map((item) => {
            const phoneCase = phoneCases.find(
              (pc) =>
                pc.brand === item.phoneCaseBrand &&
                pc.productDescription.includes(item.phoneCaseModel)
            );
            return {
              ...item,
              price: item.includePhoneCase
                ? (phoneCase?.price || 0) * 1.2 + 5.50
                : 2.00,
              quantity: item.quantity || 1,
            };
          }),
          shippingAddress: requiresAddress ? deliveryAddress : null,
          shippingOption: requiresAddress ? shippingOption : null,
          email: requiresEmail ? email : null,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { url } = await response.json();

      if (url) {
        console.log("Redirecting to Stripe Checkout URL:", url);
        window.location.href = url;
      } else {
        console.error('Stripe session URL is not defined');
      }
    } catch (error) {
      console.error('Failed to initiate purchase:', error);
    } finally {
      setPurchaseDisabled(false); // Re-enable the button after submission
    }
  };

  if (isLoading) return <div>Loading...</div>;

  const needsDeliveryDetails = items.some((item) => item.includePhoneCase);
  const totalFormatted = calculateTotalCost.toFixed(2);

  return (
    <div className="basket container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Your Basket</h2>
      {items.length === 0 ? (
        <p>Your basket is empty.</p>
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            {items.map((item, index) => (
              <div key={index} className="basket-item border p-4 rounded shadow">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}${item.url}`}
                  alt={`Wallpaper ${index}`}
                  className="w-full h-32 object-cover mb-2 rounded"
                />
                <p className="font-bold">{item.description}</p>
                <p>Color: {colorNameFromHex(item.color)}</p>
                <p>Price: £2.00</p>
                <button
                  onClick={() => handleRemoveItem(item.url)}
                  className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                >
                  Remove
                </button>
                <div className="mt-2">
                  <label>
                    <input
                      type="checkbox"
                      checked={item.includePhoneCase || false}
                      onChange={() =>
                        setBasket((prev) =>
                          prev.map((i, idx) =>
                            idx === index
                              ? {
                                  ...i,
                                  includePhoneCase: !i.includePhoneCase,
                                  phoneCaseBrand: '',
                                  phoneCaseModel: '',
                                }
                              : i
                          )
                        )
                      }
                    />
                    Matching Phone Case (from £14.50)
                  </label>
                  {item.includePhoneCase && (
                    <>
                      <select
                        value={item.phoneCaseBrand || ''}
                        onChange={(e) =>
                          setBasket((prev) =>
                            prev.map((i, idx) =>
                              idx === index ? { ...i, phoneCaseBrand: e.target.value, phoneCaseModel: '' } : i
                            )
                          )
                        }
                        className="w-full border rounded p-2 mt-2"
                      >
                        <option value="">Select Brand</option>
                        {getBrands().map((brand, i) => (
                          <option key={i} value={brand}>
                            {brand}
                          </option>
                        ))}
                      </select>
                      <select
                        value={item.phoneCaseModel || ''}
                        onChange={(e) =>
                          setBasket((prev) =>
                            prev.map((i, idx) =>
                              idx === index ? { ...i, phoneCaseModel: e.target.value } : i
                            )
                          )
                        }
                        className="w-full border rounded p-2 mt-2"
                      >
                        <option value="">Select Model</option>
                        {getModelsByBrand(item.phoneCaseBrand).map((model, i) => (
                          <option key={i} value={model}>
                            {model}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          {needsDeliveryDetails && (
            <div>
              <h3 className="text-xl font-bold mb-2">Delivery Details</h3>
              <input
                type="text"
                placeholder="Name"
                value={deliveryAddress.name}
                onChange={(e) => setDeliveryAddress((prev) => ({ ...prev, name: e.target.value }))}
                className="w-full border rounded p-2 mb-2"
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border rounded p-2 mb-2"
              />
              <input
                type="text"
                placeholder="Address Line 1"
                value={deliveryAddress.line1}
                onChange={(e) => setDeliveryAddress((prev) => ({ ...prev, line1: e.target.value }))}
                className="w-full border rounded p-2 mb-2"
              />
              <input
                type="text"
                placeholder="Address Line 2"
                value={deliveryAddress.line2}
                onChange={(e) => setDeliveryAddress((prev) => ({ ...prev, line2: e.target.value }))}
                className="w-full border rounded p-2 mb-2"
              />
              <input
                type="text"
                placeholder="Town or City"
                value={deliveryAddress.townOrCity}
                onChange={(e) => setDeliveryAddress((prev) => ({ ...prev, townOrCity: e.target.value }))}
                className="w-full border rounded p-2 mb-2"
              />
              <input
                type="text"
                placeholder="Postal or Zip Code"
                value={deliveryAddress.postalOrZipCode}
                onChange={(e) => setDeliveryAddress((prev) => ({ ...prev, postalOrZipCode: e.target.value }))}
                className="w-full border rounded p-2 mb-2"
              />
              <select
                value={shippingOption?.id || ''}
                onChange={(e) => setShippingOption(shippingOptions.find((so) => so.id === e.target.value))}
                className="w-full border rounded p-2 mb-2"
              >
                <option value="">Select Shipping Option</option>
                {shippingOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formErrors.phoneCaseSelection && (
                <p className="text-red-600">{formErrors.phoneCaseSelection}</p>
              )}
            </div>
          )}

          <div className="fixed bottom-0 left-0 w-full bg-white shadow-md py-4 px-6 flex justify-between items-center border-t">
            <p className="text-xl font-bold">Total: £{totalFormatted}</p>
            <button
              onClick={handlePurchase}
              className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
              disabled={purchaseDisabled}
            >
              Purchase
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Basket;
