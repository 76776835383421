import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

function Header({
  onColorChange,
  onThemeChange,
  onArtistChange,
  onGenerate,
  includeSportsTeam,
  setIncludeSportsTeam,
  setSportsTeam,
  isGenerating,
  onSportsTeamChange,
  onNavigateToBasket,
  basketCount,
}) {
  const handleColorChange = useCallback((event) => {
    onColorChange(event.target.value);
  }, [onColorChange]);

  const handleThemeChange = useCallback((event) => {
    onThemeChange(event.target.value);
  }, [onThemeChange]);

  const handleArtistChange = useCallback((event) => {
    onArtistChange(event.target.value);
  }, [onArtistChange]);

  const handleSportsTeamChange = useCallback((event) => {
    onSportsTeamChange(event.target.value);
  }, [onSportsTeamChange]);

  return (
    <header className="bg-green-200 px-4 py-2 flex flex-wrap justify-between items-center sticky top-0 w-full">
      <div className="flex items-center">
        <label htmlFor="color-picker" className="mr-2">
          Dominant Colour:
        </label>
        <input
          id="color-picker"
          type="color"
          className="p-2"
          onChange={handleColorChange}
        />
      </div>

      <div className="flex items-center">
        <label htmlFor="theme-dropdown" className="mr-2">
          Theme:
        </label>
        <select
          id="theme-dropdown"
          className="mx-2 p-2 bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          onChange={handleThemeChange}
        >
          {/* Add your theme options here */}
          <option value="random">Choose</option>
          <option value="abstract">Abstract</option>
          <option value="animals">Animals</option>
          <option value="art-deco">Art Deco</option>
          <option value="art-nouveau">Art Nouveau</option>
          <option value="beach life">Beach Life</option>
          <option value="botanical">Botanical</option>
          <option value="cats">Cats</option>
	            {/* Christmas Subcategories */}
          <optgroup label="Christmas">
            <option value="christmas-baubles">Christmas Baubles</option>
            <option value="christmas-lights">Christmas Lights</option>
            <option value="christmas-trees">Christmas Trees</option>
            <option value="snowflakes">Snowflakes</option>
            <option value="santa-claus">Santa Claus</option>
            <option value="reindeer">Reindeer</option>
            <option value="winter-scenes">Winter Scenes</option>
            <option value="gingerbread">Gingerbread</option>
            <option value="holly-and-mistletoe">Holly and Mistletoe</option>
            <option value="christmas-markets">Christmas Markets</option>
          </optgroup>
          <option value="cityscape">Cityscape</option>
          <option value="clouds">Clouds</option>
          <option value="cubism">Cubism</option>
          <option value="cyberpunk">Cyberpunk</option>
          <option value="dogs">Dogs</option>
          <option value="fabric">Fabric</option>
          <option value="fantasy">Fantasy</option>
          <option value="floral">Floral</option>
          <option value="forests">Forests</option>
          <option value="fractals">Fractals</option>
          <option value="fruit">Fruit</option>
          <option value="futuristic">Futuristic</option>
          <option value="geometric">Geometric</option>
          <option value="graffiti">Graffiti</option>
          <option value="historical">Historical</option>
          <option value="kaleidoscope">Kaleidoscope</option>
          <option value="luna">Luna</option>
          <option value="mandalas">Mandalas</option>
          <option value="modern-art">Modern Art</option>
          <option value="mountains">Mountains</option>
          <option value="music">Music</option>
          <option value="nature">Nature</option>
          <option value="nebulae">Nebulae</option>
          <option value="ocean">Ocean</option>
          <option value="pop-art">Pop Art</option>
          <option value="psychedelic">Psychedelic</option>
          <option value="retro">Retro</option>
          <option value="seascape">Seascape</option>
          <option value="seasonal">Seasonal</option>
          <option value="space">Space</option>
          <option value="space tech">Space Tech</option>
          <option value="stained glass">Stained Glass</option>
          <option value="street-art">Street Art</option>
          <option value="summer">Summer</option>
          <option value="sunset">Sunset</option>
          <option value="technology">Technology</option>
          <option value="underwater">Underwater</option>
          <option value="urban">Urban</option>
          <option value="vintage">Vintage</option>
        </select>
      </div>

      <div className="flex items-center">
        <label htmlFor="artist-dropdown" className="mr-2">
          Artist:
        </label>
        <select
          id="artist-dropdown"
          className="mx-2 p-2 bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          onChange={handleArtistChange}
        >
          {/* Add your artist options here */}
          <option value="your own style">Choose or leave blank</option>
          <option value="Photorealistic">Photorealistic</option>
          <option value="Giotto">Giotto (Proto-Renaissance)</option>
          <option value="Botticelli">Sandro Botticelli (Early Renaissance)</option>
          <option value="Da Vinci">Leonardo da Vinci (Renaissance)</option>
          <option value="Michelangelo">Michelangelo (Renaissance)</option>
          <option value="Raphael">Raphael (Renaissance)</option>
          <option value="Titian">Titian (Renaissance)</option>
          <option value="El Greco">El Greco (Mannerism)</option>
          <option value="Caravaggio">Caravaggio (Baroque)</option>
          <option value="Rubens">Peter Paul Rubens (Baroque)</option>
          <option value="Rembrandt">Rembrandt (Baroque)</option>
          <option value="Vermeer">Johannes Vermeer (Baroque)</option>
          <option value="Fragonard">Jean-Honoré Fragonard (Rococo)</option>
          <option value="Ingres">Jean-Auguste-Dominique Ingres (Neoclassicism)</option>
          <option value="Constable">John Constable (Romanticism)</option>
          <option value="Delacroix">Eugène Delacroix (Romanticism)</option>
          <option value="Goya">Francisco Goya (Romanticism)</option>
          <option value="Turner">J.M.W. Turner (Romanticism)</option>
          <option value="Whistler">James McNeill Whistler (Realism/Impressionism)</option>
          <option value="Degas">Edgar Degas (Impressionism)</option>
          <option value="Monet">Claude Monet (Impressionism)</option>
          <option value="Renoir">Pierre-Auguste Renoir (Impressionism)</option>
          <option value="Cassatt">Mary Cassatt (Impressionism)</option>
          <option value="Cezanne">Paul Cézanne (Post-Impressionism)</option>
          <option value="Gauguin">Paul Gauguin (Post-Impressionism)</option>
          <option value="Seurat">Georges Seurat (Post-Impressionism)</option>
          <option value="Toulouse-Lautrec">Henri de Toulouse-Lautrec (Post-Impressionism)</option>
          <option value="VanGogh">Vincent van Gogh (Post-Impressionism)</option>
          <option value="Hiroshige">Utagawa Hiroshige (Ukiyo-e)</option>
          <option value="Hokusai">Katsushika Hokusai (Ukiyo-e)</option>
          <option value="Munch">Edvard Munch (Symbolism)</option>
          <option value="Klimt">Gustav Klimt (Symbolism/Modernism)</option>
          <option value="Matisse">Henri Matisse (Fauvism)</option>
          <option value="Braque">Georges Braque (Cubism)</option>
          <option value="Léger">Fernand Léger (Cubism)</option>
          <option value="Picasso">Pablo Picasso (Cubism)</option>
          <option value="Giacomo Balla">Giacomo Balla (Futurism)</option>
          <option value="Kandinsky">Wassily Kandinsky (Abstract)</option>
          <option value="Mondrian">Piet Mondrian (Abstract)</option>
          <option value="Chagall">Marc Chagall (Modernism)</option>
          <option value="Escher">M.C. Escher (Modernism)</option>
          <option value="Modigliani">Amadeo Modigliani (Modernism)</option>
          <option value="Kahlo">Frida Kahlo (Surrealism/Modernism)</option>
          <option value="O'Keeffe">Georgia O'Keeffe (American Modernism)</option>
          <option value="Albers">Josef Albers (Bauhaus/Modernism)</option>
          <option value="Duchamp">Marcel Duchamp (Dada/Surrealism)</option>
          <option value="Dali">Salvador Dalí (Surrealism)</option>
          <option value="Ernst">Max Ernst (Surrealism)</option>
          <option value="Giacometti">Alberto Giacometti (Surrealism)</option>
          <option value="Magritte">René Magritte (Surrealism)</option>
          <option value="Miró">Joan Miró (Surrealism)</option>
          <option value="Klee">Paul Klee (Expressionism/Surrealism)</option>
          <option value="Schiele">Egon Schiele (Expressionism)</option>
          <option value="Rivera">Diego Rivera (Mexican Muralism)</option>
          <option value="Hopper">Edward Hopper (Realism)</option>
          <option value="Shahn">Ben Shahn (Social Realism)</option>
          <option value="Pollock">Jackson Pollock (Abstract Expressionism)</option>
          <option value="Rothko">Mark Rothko (Abstract Expressionism)</option>
        </select>
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          id="include-sports-team"
          checked={includeSportsTeam}
          onChange={(e) => setIncludeSportsTeam(e.target.checked)}
        />
        <label htmlFor="include-sports-team" className="ml-2">
          Add Sports Team inspiration!
        </label>
      </div>

      {includeSportsTeam && (
        <div className="flex items-center">
          <label htmlFor="sports-team-dropdown" className="mr-2">
            Sports Team:
          </label>
          <select
            id="sports-team-dropdown"
            className="mx-2 p-2 bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={handleSportsTeamChange}
          >
            {/* Add your sports team options here */}
            <option value="general sports">Choose or leave blank</option>
            <option value="soccer-barcelona">FC Barcelona (Soccer)</option>
            <option value="soccer-bayern-munich">FC Bayern Munich (Soccer)</option>
            <option value="soccer-chelsea">Chelsea FC (Soccer)</option>
            <option value="soccer-juventus">Juventus FC (Soccer)</option>
            <option value="soccer-liverpool">Liverpool FC (Soccer)</option>
            <option value="soccer-manchester-city">Manchester City FC (Soccer)</option>
            <option value="Soccer, Manchester United">Manchester United FC (Soccer)</option>
            <option value="soccer-real-madrid">Real Madrid CF (Soccer)</option>

            <option value="basketball-boston-celtics">Boston Celtics (Basketball)</option>
            <option value="basketball-chicago-bulls">Chicago Bulls (Basketball)</option>
            <option value="basketball-golden-state-warriors">Golden State Warriors (Basketball)</option>
            <option value="basketball-los-angeles-lakers">Los Angeles Lakers (Basketball)</option>

            <option value="american-football-dallas-cowboys">Dallas Cowboys (American Football)</option>
            <option value="american-football-new-england-patriots">New England Patriots (American Football)</option>
            <option value="american-football-pittsburgh-steelers">Pittsburgh Steelers (American Football)</option>

            <option value="baseball-new-york-yankees">New York Yankees (Baseball)</option>
            <option value="baseball-los-angeles-dodgers">Los Angeles Dodgers (Baseball)</option>

            <option value="ice-hockey-chicago-blackhawks">Chicago Blackhawks (Ice Hockey)</option>
            <option value="ice-hockey-montreal-canadiens">Montreal Canadiens (Ice Hockey)</option>

            <option value="cricket-mumbai-indians">Mumbai Indians (Cricket)</option>
            <option value="cricket-chennai-super-kings">Chennai Super Kings (Cricket)</option>

            <option value="rugby-all-blacks">All Blacks (Rugby)</option>
            <option value="rugby-wallabies">Australia Wallabies (Rugby)</option>

            <option value="formula-one-ferrari">Scuderia Ferrari (Formula One)</option>
            <option value="formula-one-mercedes">Mercedes-AMG Petronas (Formula One)</option>
          </select>
        </div>
      )}

      <button
        className="generate-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={onGenerate}
        disabled={isGenerating}
      >
        {isGenerating ? 'Generating...' : 'Generate'}
      </button>

      <button
        onClick={onNavigateToBasket}
        className="basket-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        View Basket ({basketCount})
      </button>
    </header>
  );
}

export default Header;
