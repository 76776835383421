import React from 'react';
import { Link } from 'react-router-dom';
import wallpaperImage from '../assets/generate-image.png';
import downloadImage from '../assets/download-image.png';
import phoneCaseImage from '../assets/phone-case-image.png';
import iPadAndCaseImage from '../assets/ipad_and_case.jpeg';

const HowItWorks = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">How It Works</h1>
      <div className="steps mb-4">
        <div className="step mb-4">
          <h2 className="text-2xl font-bold mb-2">1. Generate Your Wallpaper</h2>
          <img src={wallpaperImage} alt="Generate Wallpaper" style={{ width: '30%' }} className="mx-auto mb-2" />
          <p>Choose your favorite color, theme, and artist inspiration to create a completely unique wallpaper, exclusively yours.</p>
        </div>
        <div className="step mb-4">
          <h2 className="text-2xl font-bold mb-2">2. Download Your Wallpaper</h2>
          <img src={downloadImage} alt="Download Wallpaper" style={{ width: '30%' }} className="mx-auto mb-2" />
          <p>After generating, download your exclusive, custom wallpaper for your devices.</p>
        </div>
        <div className="step mb-4">
          <h2 className="text-2xl font-bold mb-2">3. Treat yourself - Order a Matching Phone Case!</h2>
          <img src={phoneCaseImage} alt="Phone Case" style={{ width: '30%' }} className="mx-auto mb-2" />
          <p>Why not order a phone case with your unique wallpaper printed on it? Cases usually ship within 48 hours and arrive within 1-4 working days (3 shipping options available)</p>
          <img src={iPadAndCaseImage} alt="iPad and Case" style={{ width: '50%', display: 'block', margin: '0 auto 20px' }} />
          <Link to="/phone-cases" className="text-blue-500 hover:text-blue-700">
            Check out our phone cases!
          </Link>
        </div>
      </div>
      <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Home
      </Link>
    </div>
  );
};

export default HowItWorks;
