import React from 'react';
import { Link } from 'react-router-dom';
import snapCaseImage from '../assets/snap_case.png';
import toughCaseImage from '../assets/tough_case.png';
import ecoCaseImage from '../assets/eco_case.png';

const PhoneCases = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Phone Cases</h1>
      <p className="mb-4">
        We have a selection of phone cases available to match your exclusive wallpaper, from sleek and minimalistic designs to robust and eco-friendly options.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Eco Case</h2>
          <img src={ecoCaseImage} alt="Eco Case" className="mx-auto mb-2" style={{ width: '40%' }} />
          <p>The Eco Case is eco-friendly, biodegradable, and free from harmful chemicals. Made from a bioplastic and bamboo fiber mix, it is shock-absorbent, scratch-resistant, and has a natural finish.</p>
        </div>
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Folio Case</h2>
          <p>The Folio Case is a faux-leather wallet-style case with three inner pockets, space for cards and cash, a built-in stand, and an elastic strap fastening. It has a satin finish.</p>
        </div>
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Snap Case</h2>
          <img src={snapCaseImage} alt="Snap Case" className="mx-auto mb-2" style={{ width: '40%' }} />
          <p>Compact and lightweight, the Snap Case is made from impact-resistant plastic. It offers durable protection without bulk, available in matte or gloss finish with edge-to-edge printing.</p>
        </div>
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Clear Case</h2>
          <p>The Clear Case is slim, lightweight, and made from shock-absorbing TPU and transparent plastic. It features a 2D custom printed area covering the entire back.</p>
        </div>
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Tough Case</h2>
          <img src={toughCaseImage} alt="Tough Case" className="mx-auto mb-2" style={{ width: '40%' }} />
          <p>The Tough Case combines a lightweight, impact-resistant plastic outer shell with a shock-absorbing TPU lining. It comes in a matte or gloss finish with a 3D-wrapped print.</p>
        </div>
        <div className="case mb-4">
          <h2 className="text-2xl font-bold mb-2">Flexi Case</h2>
          <p>The Flexi Case is slim, lightweight, and has a flexible clear coat layer. Made from shock-absorbent TPU, it offers impact protection with a satin finish.</p>
        </div>
      </div>
      <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Home
      </Link>
    </div>
  );
};

export default PhoneCases;
