import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ onNavigateToUpdates }) {
  return (
    <footer className="footer bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-center items-center">
        <p>© Copyright phonewallpapers.co.uk 2024</p>
        <span className="mx-2">|</span>
        <Link to="/how-it-works" className="text-blue-500 hover:text-blue-700">How It Works</Link>
        <span className="mx-2">|</span>
        <a href="https://us22.list-manage.com/contact-form?u=195827dc2626a774a3aeddc21&form_id=02a09c92175c01fe8426d2b96fb9fcfa" 
           className="ml-4 text-blue-600 hover:text-blue-800 visited:text-purple-600 mr-8">
          Contact Us
        </a>
        <span className="mx-2">|</span>
        <Link to="/phone-cases" className="text-blue-500 hover:text-blue-700">Our Phone Cases</Link>
      </div>
    </footer>
  );
}

export default Footer;
