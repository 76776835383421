import React, { useState, useCallback, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Wallpaper from './components/Wallpaper';
import Footer from './components/Footer';
import Basket from './components/Basket';
import UpdatesForm from './components/UpdatesForm';
import DownloadPage from './components/DownloadPage';
import HowItWorks from './components/HowItWorks';
import ErrorBoundary from './components/ErrorBoundary';
import PhoneCases from './components/PhoneCases';

function App() {
  const [color, setColor] = useState('blue');
  const [theme, setTheme] = useState('fractals');
  const [artist, setArtist] = useState('Dali');
  const [wallpaper, setWallpaper] = useState(null);
  const [basket, setBasket] = useState([]);
  const [sportsTeam, setSportsTeam] = useState('');
  const [includeSportsTeam, setIncludeSportsTeam] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const [showBasket, setShowBasket] = useState(false);
  const [showUpdatesForm, setShowUpdatesForm] = useState(false);

  const itemPrice = 2.00;
  const totalPrice = useMemo(() => basket.length * itemPrice, [basket]);

  const serverUrl = process.env.REACT_APP_API_BASE_URL;

  const getRGB = useCallback((colorHex) => {
    const r = parseInt(colorHex.substr(1, 2), 16);
    const g = parseInt(colorHex.substr(3, 2), 16);
    const b = parseInt(colorHex.substr(5, 2), 16);
    return { r, g, b };
  }, []);

  const colorDistance = useCallback((color1, color2) => {
    const rgb1 = getRGB(color1);
    const rgb2 = getRGB(color2);
    return Math.sqrt(Math.pow(rgb2.r - rgb1.r, 2) + Math.pow(rgb2.g - rgb1.g, 2) + Math.pow(rgb2.b - rgb1.b, 2));
  }, [getRGB]);

  const colorNameFromHex = useCallback((hex) => {
    const colors = {
      '#ff0000': 'Red',
      '#00ff00': 'Green',
      '#0000ff': 'Blue',
      '#000000': 'Black',
      '#ffffff': 'White',
      '#ffff00': 'Yellow',
      '#ff00ff': 'Magenta',
      '#00ffff': 'Cyan',
      '#800000': 'Maroon',
      '#808000': 'Olive',
      '#008000': 'Dark Green',
      '#800080': 'Purple',
      '#008080': 'Teal',
      '#c0c0c0': 'Silver',
      '#808080': 'Gray',
      '#400000': 'Dark Red',
      '#404000': 'Dark Yellow',
      '#004000': 'Green (Web)',
      '#400040': 'Dark Purple',
      '#004040': 'Deep Aqua',
      '#804000': 'Brown',
      '#ffa500': 'Orange',
      '#ff4500': 'Orange Red',
      '#da70d6': 'Orchid',
      '#eee8aa': 'Pale Goldenrod',
      '#6b8e23': 'Olive Drab',
      '#ff6347': 'Tomato',
      '#4682b4': 'Steel Blue',
      '#b0c4de': 'Light Steel Blue',
      '#87ceeb': 'Sky Blue',
      '#2e8b57': 'Sea Green',
      '#f0e68c': 'Khaki',
      '#663399': 'Rebecca Purple'
    };
    let closestColor = { name: "Unknown", distance: Infinity };
    Object.keys(colors).forEach(key => {
      let dist = colorDistance(hex, key);
      if (dist < closestColor.distance) {
        closestColor = { name: colors[key], distance: dist };
      }
    });
    return closestColor.name;
  }, [colorDistance]);

  const navigateToBasket = useCallback(() => {
    setShowBasket(true);
    setShowUpdatesForm(false);
  }, []);

  const navigateHome = useCallback(() => {
    setShowBasket(false);
    setShowUpdatesForm(false);
  }, []);

  const handleNavigateToUpdates = useCallback(() => {
    setShowUpdatesForm(true);
    setShowBasket(false);
  }, []);

  const handleAddToBasket = useCallback(() => {
    if (!basket.some(item => item.url === wallpaper)) {
      const newItem = {
        url: wallpaper,
        description: `inspired by ${artist} with a theme of ${theme}`,
        color: color
      };
      setBasket(prevBasket => [...prevBasket, newItem]);
    }
  }, [wallpaper, artist, theme, color, basket]);

  const handleGenerateWallpaper = useCallback(async () => {
    console.log(`Color before request: ${color}`);
    if (generationCount >= 10) {
      navigateToBasket();
      return;
    }
    setIsGenerating(true);
    let description = `A wallpaper with the theme of ${theme}, dominant color ${color}, inspired by the art of ${artist}`;
    if (includeSportsTeam && sportsTeam) {
      description += `, with elements heavily inspired by the sports team ${sportsTeam}`;
    }
    console.log(`Generating wallpaper with description: ${description}`);

    try {
      const response = await fetch(`${serverUrl}/generate-wallpaper`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: description }),
      });

      console.log('API response status:', response.status);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API response data:', data); 
      if (data.filePath) {
        console.log('Setting wallpaper to:', data.filePath);
        setWallpaper(data.filePath);
        setHasGenerated(true);
        setGenerationCount(prevCount => prevCount + 1);
      }
    } catch (error) {
      console.error("Failed to generate wallpaper:", error);
    } finally {
      setIsGenerating(false);
    }
  }, [color, theme, artist, includeSportsTeam, sportsTeam, generationCount, navigateToBasket, serverUrl]);

  return (
    <ErrorBoundary>
      <div className="app flex flex-col min-h-screen">
        <Header
          onColorChange={setColor}
          onThemeChange={setTheme}
          onArtistChange={setArtist}
          onSportsTeamChange={setSportsTeam}
          onGenerate={handleGenerateWallpaper}
          includeSportsTeam={includeSportsTeam}
          setIncludeSportsTeam={setIncludeSportsTeam}
          setSportsTeam={setSportsTeam}
          isGenerating={isGenerating}
          onNavigateToBasket={navigateToBasket}
          basketCount={basket.length}
          generationCount={generationCount}
          onNavigateToUpdates={handleNavigateToUpdates}
        />
        <main className="flex-grow">
          <Routes>
            <Route path="/success" element={<DownloadPage />} />
            <Route path="/how-it-works" element={<HowItWorks onNavigateHome={navigateHome} />} />
            <Route path="/phone-cases" element={<PhoneCases />} />
            <Route path="/" element={
              <React.Fragment>
                {showBasket ? (
                  <Basket
                    items={basket}
                    setBasket={setBasket}
                    onNavigateHome={navigateHome}
                    colorNameFromHex={colorNameFromHex}
                    totalPrice={totalPrice}
                  />
                ) : showUpdatesForm ? (
                  <UpdatesForm onNavigateHome={navigateHome} />
                ) : (
                  <React.Fragment>
                    <Wallpaper
                      wallpaper={wallpaper}
                      onAddToBasket={handleAddToBasket}
                      hasGenerated={hasGenerated}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            } />
          </Routes>
        </main>
        <Footer onNavigateToUpdates={handleNavigateToUpdates} />
      </div>
    </ErrorBoundary>
  );
}

export default App;
